.react-tabs {
    -webkit-tap-highlight-color: transparent;
    width: 100%;
}

.react-tabs__tab-list {
    border-top: 1px solid #DEE2E6;
    border-bottom: 1px solid #DEE2E6;;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.react-tabs__tab {
    font-family: chosun;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
    color: rgba(73, 80, 87, 0.5);

    flex-basis: 33%;
    display: inline-block;
    border-bottom: none;
    bottom: -3px;
    position: relative;
    list-style: none;
    padding: 13px 0 10px 0;
    cursor: pointer;
}

.react-tabs__tab--selected {
    color: rgba(0, 0, 0, 1);
    border-bottom: 3px solid #403B36;
}

.react-tabs__tab--disabled {
    color: #495057;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

/*.react-tabs__tab:focus:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  height: 5px;*/
/*  left: -4px;*/
/*  right: -4px;*/
/*  bottom: -5px;*/
/*  !*background: #fff;*!*/
/*}*/

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}
