@font-face {
    font-family: "chosun";
    font-style: normal;
    font-weight: 100;
    src: url("ChosunCentennial_otf.otf") format("opentype");
}

@font-face {
    font-family: "pretendard";
    font-style: normal;
    font-weight: 100;
    src: url("Pretendard-Regular.otf") format("opentype");
}

@font-face {
    font-family: "gangwon";
    font-style: normal;
    font-weight: 100;
    src: url("GangnwonEduPower.otf") format("opentype");
}